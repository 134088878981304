import React from "react";
import { Link } from "gatsby";
import Swiper from "react-id-swiper";
import Img from "gatsby-image";
import { Pagination, Navigation } from "swiper/dist/js/swiper.esm";
import "react-id-swiper/src/styles/scss/swiper.scss";
import "../../styles/global/vendor-override.sass";

import Title from "../Title";
import style from "./risks.module.sass";

const Risks = ({ images }) => {
    const params = {
        modules: [Pagination, Navigation],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            dynamicBullets: true,
        },
    };

    return (
        <section className={style.risks}>
            <div className={style.risks__inner}>
                <Title type="h2" color="gold">
                    Rizika spojená s nekorektním dodavatelem
                </Title>
                {/*<p>
                    Přestože zlato tradičně funguje jako rezerva a protiváha
                    ostatním investičním produktům, je potřeba dávat na některé
                    věcí pozor. Které to jsou?
                </p>*/}
                <div className={style.mobileSliderWrapper}>
                    <Swiper {...params} containerClass={style.mobileSlider}>
                        <div className={style.mobileSlider__item}>
                            <Img
                                fluid={images.levneZlato}
                                className={style.image}
                            />
                            <div className={style.mobileSlider__itemInner}>
                                <h3>Podezřele levné zlato</h3>
                                <p>
                                    Zlato nejistého původu, například
                                    z&nbsp;velkých tržnic na&nbsp;Blízkém
                                    východě nebo Egyptě, může být padělek nebo
                                    mít nízkou ryzost. Zlato KAPITOLu má vždy
                                    záruku původu, protože jde o&nbsp;originální
                                    zlatý slitek s&nbsp;možností ověření
                                    technologií{" "}
                                    <Link to="/zajimavosti-o-investicnich-kovech/veriscan-overte-si-velmi-jednoduse-pravost-zlateho-slitku">
                                        Veriscan
                                    </Link>
                                    .
                                </p>
                            </div>
                        </div>
                        <div className={style.mobileSlider__item}>
                            <Img
                                fluid={images.krachDodavatele}
                                className={style.image}
                            />
                            <div className={style.mobileSlider__itemInner}>
                                <h3>
                                    Krach
                                    <br />
                                    dodavatele
                                </h3>
                                <p>
                                    Zásadní rada zní - nekupujte zlato
                                    od&nbsp;neznámých či&nbsp;malých dodavatelů,
                                    kteří nejsou pojištěni proti úpadku
                                    a&nbsp;ztrátě při&nbsp;zaslání. Zlato
                                    od&nbsp;KAPITOLu je&nbsp;pojištěné vždy
                                    a&nbsp;vy tak&nbsp;máte jistotu,
                                    že&nbsp;o&nbsp;své peníze nepřijdete.
                                </p>
                            </div>
                        </div>
                        <div className={style.mobileSlider__item}>
                            <Img
                                fluid={images.vysokyPoplatek}
                                className={style.image}
                            />
                            <div className={style.mobileSlider__itemInner}>
                                <h3>Vysoký poplatek na&nbsp;malé slitky</h3>
                                <p>
                                    Při&nbsp;nákupu menších slitků se&nbsp;může
                                    marže obchodníka vyšplhat až na&nbsp;50
                                    procent. Pozor si na&nbsp;to&nbsp;dejte
                                    zejména u&nbsp;jednogramových slitků.
                                    KAPITOL z&nbsp;tohoto důvodu nabízí zlaté
                                    slitky o&nbsp;minimální hmotnosti 5&nbsp;g.
                                </p>
                            </div>
                        </div>
                        <div className={style.mobileSlider__item}>
                            <Img
                                fluid={images.zadnePadelky}
                                className={style.image}
                            />
                            <div className={style.mobileSlider__itemInner}>
                                <h3>U nás žádné padělky</h3>
                                <p>
                                    U&nbsp;zlatých slitků je&nbsp;snazší vyvrtat
                                    dutinku a&nbsp;vyplnit ji wolframem,
                                    který&nbsp;má stejnou hustotu jako zlato
                                    a&nbsp;bez ultrazvuku při&nbsp;pouhém vážení
                                    nic nepoznáte. Naše investiční zlato
                                    z&nbsp;rafinerie PAMP má unikátní ražbu
                                    a&nbsp;je&nbsp;téměř nemožné jej padělat.
                                </p>
                            </div>
                        </div>
                    </Swiper>
                </div>

                <div className={style.risks__items}>
                    <div className={style.risks__item}>
                        <Img
                            fluid={images.levneZlato}
                            className={style.image}
                        />
                        <div className={style.risks__item_inner}>
                            <h3>Podezřele levné zlato</h3>
                            <p>
                                Zlato nejistého původu, například z&nbsp;velkých
                                tržnic na&nbsp;Blízkém východě nebo Egyptě, může
                                být padělek nebo mít nízkou ryzost. Zlato
                                KAPITOLu má vždy záruku původu, protože jde
                                o&nbsp;originální zlatý slitek s&nbsp;možností
                                ověření technologií{" "}
                                <Link to="/zajimavosti-o-investicnich-kovech/veriscan-overte-si-velmi-jednoduse-pravost-zlateho-slitku">
                                    Veriscan
                                </Link>
                                .
                            </p>
                        </div>
                    </div>
                    <div className={style.risks__item}>
                        <Img
                            fluid={images.krachDodavatele}
                            className={style.image}
                        />
                        <div className={style.risks__item_inner}>
                            <h3>
                                Krach
                                <br />
                                dodavatele
                            </h3>
                            <p>
                                Zásadní rada zní - nekupujte zlato
                                od&nbsp;neznámých či&nbsp;malých dodavatelů,
                                kteří nejsou pojištěni proti úpadku
                                a&nbsp;ztrátě při&nbsp;zaslání. Zlato
                                od&nbsp;KAPITOLu je&nbsp;pojištěné vždy
                                a&nbsp;vy tak&nbsp;máte jistotu,
                                že&nbsp;o&nbsp;své peníze nepřijdete.
                            </p>
                        </div>
                    </div>
                    <div className={style.risks__item}>
                        <Img
                            fluid={images.vysokyPoplatek}
                            className={style.image}
                        />
                        <div className={style.risks__item_inner}>
                            <h3>Vysoký poplatek na&nbsp;malé slitky</h3>
                            <p>
                                Při&nbsp;nákupu menších slitků se&nbsp;může
                                marže obchodníka vyšplhat až na&nbsp;50 procent.
                                Pozor si na&nbsp;to&nbsp;dejte zejména
                                u&nbsp;jednogramových slitků. KAPITOL
                                z&nbsp;tohoto důvodu nabízí zlaté slitky
                                o&nbsp;minimální hmotnosti 5&nbsp;g.
                            </p>
                        </div>
                    </div>
                    <div className={style.risks__item}>
                        <Img
                            fluid={images.zadnePadelky}
                            className={style.image}
                        />
                        <div className={style.risks__item_inner}>
                            <h3>U nás žádné padělky</h3>
                            <p>
                                U&nbsp;zlatých slitků je&nbsp;snazší vyvrtat
                                dutinku a&nbsp;vyplnit ji wolframem,
                                který&nbsp;má stejnou hustotu jako zlato
                                a&nbsp;bez ultrazvuku při&nbsp;pouhém vážení nic
                                nepoznáte. Naše investiční zlato
                                z&nbsp;rafinerie PAMP má unikátní ražbu
                                a&nbsp;je&nbsp;téměř nemožné jej padělat.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Risks;
