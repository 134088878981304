import React from "react";
import Img from "gatsby-image";
import classNames from "classnames";

import Title from "../Title";
import { Link } from "gatsby";
import style from "./pageHeader.module.sass";
import button from "../Button/button.module.sass";
import { TYPE_SILVER } from "../../constants";

import PriceHistory from "../PriceHistory";

const PageHeader = ({ type, image, title, content }) => (
    <div className={style.pageHeader}>
        <div className={style.pageHeader__left}>
            <div
                className={classNames({
                    [style.pageHeader__text]: true,
                    [style[`pageHeader__text_${type}`]]: true,
                })}
            >
                <Title
                    type={"h1"}
                    color={type === TYPE_SILVER ? "grayBlueDark" : type}
                    mb={10}
                >
                    {title}
                </Title>

                <div dangerouslySetInnerHTML={{ __html: content }} />

                <Link
                    to={`/${type}-form`}
                    className={button.buttonBlueDark}
                    id="contactButton"
                >
                    Kontaktujte nás
                </Link>
            </div>
            <Img
                fluid={image}
                className={classNames({
                    [style.pageHeader__brick]: true,
                    [style[`pageHeader__brick_${type}`]]: true,
                })}
            />
        </div>
        <PriceHistory type={type} />
        <Img
            fluid={image}
            className={classNames({
                [style.pageHeader__brick]: true,
                [style.pageHeader__brickMobile]: true,
                [style[`pageHeader__brick_${type}`]]: true,
            })}
        />
    </div>
);

export default PageHeader;
