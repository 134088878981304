import React from "react";
import { StaticQuery, graphql } from "gatsby";

import RecentPosts from "../RecentPosts/index";

const QUERY = graphql`
    {
        wpgraphql {
            posts(first: 4) {
                nodes {
                    id
                    title
                    link
                    excerpt
                    date
                    featuredImage {
                        sourceUrl(size: MEDIUM)
                    }
                }
            }
        }
    }
`;

const RecentPostsWidget = ({ titleColor }) => (
    <StaticQuery
        query={QUERY}
        render={data => (
            <RecentPosts titleColor={titleColor} posts={data.wpgraphql.posts} />
        )}
    />
);

export default RecentPostsWidget;
