import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import style from "../WhyInvestWithUsGold/invest.module.sass";
import Title from "../Title";

class WhyInvestWithAsPlatinum extends Component {
    render = () => {
        const { mb } = this.props;

        return (
            <section
                className={classNames({
                    [style.whyInvestWithAs]: true,
                    [style.whyInvestWithAsPlatinum]: true,
                })}
                style={{ marginBottom: `${mb}px` }}
            >
                <div className={style.whyInvestWithAsInner}>
                    <div className={style.whyInvestWithAsIndent}>
                        <Title color={"platinum"} type={"h1"} mb={20}>
                            PLATINA JE INVESTIČNÍM I PRŮMYSLOVÝM KOVEM S BOHATÝM
                            VYUŽITÍM
                        </Title>
                        <p className={style.whyInvestWithAsText}>
                            Pro své vlastnosti je nenahraditelná zejména v
                            automobilovém průmyslu. Každý palivový článek
                            vozidel na vodíkový pohon obsahuje jednu unci
                            platiny, platina má využití při výrobě elektromobilů
                            i v katalyzátorech dieselových aut.
                            <br />
                            <br />
                            Historicky je platina chápána jako vzácnější než
                            zlato a také se jí těží asi desetkrát méně, čemuž
                            většinu času odpovídá i cena. Ta byla za posledních
                            sto let jen výjimečně nižší než cena zlata. Takové
                            období je i právě teď.
                        </p>
                        <Title type={"h4"} color={"white"} mb={30} mt={50}>
                            Jaké jsou další přínosy?
                        </Title>
                        <div className={style.whyInvestWithAsBenefits}>
                            <div className={style.whyInvestWithAsBenefitsRow}>
                                <div
                                    className={
                                        style.whyInvestWithAsBenefitsItem
                                    }
                                >
                                    <img
                                        src={require("../../images/icons_new/platinum.svg")}
                                        alt=""
                                    />
                                    <div>
                                        <Title
                                            color={"white"}
                                            type={"h3"}
                                            mb={8}
                                        >
                                            Vzácnost
                                        </Title>
                                        <p>
                                            Platina je až 20krát vzácnější kov
                                            než zlato.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        style.whyInvestWithAsBenefitsItem
                                    }
                                >
                                    <img
                                        src={require("../../images/icons_new/shield.svg")}
                                        alt=""
                                    />
                                    <div>
                                        <Title
                                            color={"white"}
                                            type={"h3"}
                                            mb={8}
                                        >
                                            Nenahraditelnost
                                        </Title>
                                        <p>
                                            Pro svou extrémní odolnost je
                                            nenahraditelná v průmyslu a
                                            poptávaná také v klenotnictví.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className={style.whyInvestWithAsBenefitsRow}>
                                <div
                                    className={
                                        style.whyInvestWithAsBenefitsItem
                                    }
                                >
                                    <img
                                        src={require("../../images/icons_new/platinum-graph.svg")}
                                        alt=""
                                    />
                                    <div>
                                        <Title
                                            color={"white"}
                                            type={"h3"}
                                            mb={8}
                                        >
                                            Vysoká poptávka
                                        </Title>
                                        <p>
                                            Zájem dlouhodobě převyšuje nabídku.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        style.whyInvestWithAsBenefitsItem
                                    }
                                >
                                    <img
                                        src={require("../../images/icons_new/graph.svg")}
                                        alt=""
                                    />
                                    <div>
                                        <Title
                                            color={"white"}
                                            type={"h3"}
                                            mb={8}
                                        >
                                            Nízká cena
                                        </Title>
                                        <p>
                                            Momentální nízká cena investiční
                                            platiny.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    };
}

WhyInvestWithAsPlatinum.propTypes = {
    mb: PropTypes.number,
};

WhyInvestWithAsPlatinum.defaultProps = {
    mb: 0,
};

export default WhyInvestWithAsPlatinum;
