import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import style from "../WhyInvestWithUsGold/invest.module.sass";
import Title from "../Title";

class WhyInvestWithAsSilver extends Component {
    state = {
        isOtherBenefitsOpen: false,
    };

    render = () => {
        const { isOtherBenefitsOpen } = this.state;
        const { mb } = this.props;

        return (
            <section
                className={classNames({
                    [style.whyInvestWithAs]: true,
                    [style.whyInvestWithAsSilver]: true,
                })}
                style={{ marginBottom: `${mb}px` }}
            >
                <div className={style.whyInvestWithAsInner}>
                    <div className={style.whyInvestWithAsIndent}>
                        <Title color={"silver"} type={"h1"} mb={20}>
                            INVESTIČNÍ STŘÍBRO - TO NEJSOU JEN MINCE
                        </Title>
                        <p className={style.whyInvestWithAsText}>
                            Investiční stříbro, ať už jde o mince, slitky nebo
                            cihly, je po zlatu druhým nejoblíbenějším
                            investičním kovem. Nejčastěji se prodávají slitky a
                            cihličky o váze od 5 g do 1000 g, případně stříbrné
                            mince s hmotností 31,1 gramu (jedna trojská unce).
                            <br />
                            <br />
                            Stříbro je vzácným kovem a hojně se využívá ve
                            šperkařství, v chemii, lékařství, průmyslu i ve
                            zbrojařství. Na rozdíl od zlata nenajdete stříbro v
                            rezervách centrálních bank a dlouhodobý nedostatek
                            tak ještě zvyšuje jeho hodnotu.
                        </p>
                        <Title type={"h4"} color={"white"} mb={30} mt={50}>
                            Jaké jsou další přínosy?
                        </Title>

                        <div className={style.whyInvestWithAsBenefits}>
                            <div className={style.whyInvestWithAsBenefitsRow}>
                                <div
                                    className={
                                        style.whyInvestWithAsBenefitsItem
                                    }
                                >
                                    <img
                                        src={require("../../images/icons_new/atom.svg")}
                                        alt=""
                                    />
                                    <div>
                                        <Title
                                            color={"white"}
                                            type={"h3"}
                                            mb={8}
                                        >
                                            Vyjímečnost
                                        </Title>
                                        <p>
                                            Výjimečné fyzikální vlastnosti
                                            předurčují stříbro k širokému
                                            využití v průmyslu.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        style.whyInvestWithAsBenefitsItem
                                    }
                                >
                                    <img
                                        src={require("../../images/icons_new/ring.svg")}
                                        alt=""
                                    />
                                    <div>
                                        <Title
                                            color={"white"}
                                            type={"h3"}
                                            mb={8}
                                        >
                                            Mince a šperky
                                        </Title>
                                        <p>
                                            Ze stříbra se vyrábějí oblíbené
                                            investiční mince a šperky, což
                                            zvyšuje jeho atraktivitu.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className={style.whyInvestWithAsBenefitsRow}>
                                <div
                                    className={
                                        style.whyInvestWithAsBenefitsItem
                                    }
                                >
                                    <img
                                        src={require("../../images/icons_new/cart.svg")}
                                        alt=""
                                    />
                                    <div>
                                        <Title
                                            color={"white"}
                                            type={"h3"}
                                            mb={8}
                                        >
                                            Zásoby se tenčí
                                        </Title>
                                        <p>
                                            Zásoby stříbra jsou omezené,
                                            produkce stříbrných dolů klesá a
                                            recyklace je náročná.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        style.whyInvestWithAsBenefitsItem
                                    }
                                >
                                    <img
                                        src={require("../../images/icons_new/tag.svg")}
                                        alt=""
                                    />
                                    <div>
                                        <Title
                                            color={"white"}
                                            type={"h3"}
                                            mb={8}
                                        >
                                            reálná hodnota
                                        </Title>
                                        <p>
                                            Vůči zlatu je hodnota stříbra
                                            dlouhodobě podhodnocena.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {isOtherBenefitsOpen && (
                                <>
                                    <div
                                        className={
                                            style.whyInvestWithAsBenefitsRow
                                        }
                                    >
                                        <div
                                            className={
                                                style.whyInvestWithAsBenefitsItem
                                            }
                                        >
                                            <img
                                                src={require("../../images/icons_new/chip.svg")}
                                                alt=""
                                            />
                                            <div>
                                                <Title
                                                    color={"white"}
                                                    type={"h3"}
                                                    mb={8}
                                                >
                                                    high-tech prvek
                                                </Title>
                                                <p>
                                                    Stříbro je klíčovým prvkem v
                                                    oblasti výroby elektroniky a
                                                    nových technologii.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                style.whyInvestWithAsBenefitsItem
                                            }
                                        >
                                            <img
                                                src={require("../../images/icons_new/solar.svg")}
                                                alt=""
                                            />
                                            <div>
                                                <Title
                                                    color={"white"}
                                                    type={"h3"}
                                                    mb={8}
                                                >
                                                    solární boom
                                                </Title>
                                                <p>
                                                    Poptávka po stříbru aktivně
                                                    roste kvůli obrovskému
                                                    rozmáchu výroby solárních
                                                    panelů v Indii a Číně.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={
                                            style.whyInvestWithAsBenefitsRow
                                        }
                                    >
                                        <div
                                            className={
                                                style.whyInvestWithAsBenefitsItem
                                            }
                                        >
                                            <img
                                                src={require("../../images/icons_new/coins.svg")}
                                                alt=""
                                            />
                                            <div>
                                                <Title
                                                    color={"white"}
                                                    type={"h3"}
                                                    mb={8}
                                                >
                                                    DOSTUPNOST
                                                </Title>
                                                <p>
                                                    Ceny stříbra se drží na
                                                    relativně nízké úrovni,
                                                    proto je dostupné i drobným
                                                    investorům.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                style.whyInvestWithAsBenefitsItem
                                            }
                                        >
                                            <img
                                                src={require("../../images/icons_new/graphDown.svg")}
                                                alt=""
                                            />
                                            <div>
                                                <Title
                                                    color={"white"}
                                                    type={"h3"}
                                                    mb={8}
                                                >
                                                    pojistka
                                                </Title>
                                                <p>
                                                    Stříbrem, stejně jako
                                                    zlatem, se dá pojistit proti
                                                    nestabilitě ekonomického
                                                    systému.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        {!isOtherBenefitsOpen && (
                            <div className={style.whyInvestWithAsOther}>
                                <button
                                    onClick={() =>
                                        this.setState({
                                            isOtherBenefitsOpen: true,
                                        })
                                    }
                                    className={classNames({
                                        [style.silver]: true,
                                    })}
                                >
                                    <span className={"icon-load_more"} />
                                    Další výhody
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        );
    };
}

WhyInvestWithAsSilver.propTypes = {
    mb: PropTypes.number,
};

WhyInvestWithAsSilver.defaultProps = {
    mb: 0,
};

export default WhyInvestWithAsSilver;
