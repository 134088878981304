import React from "react";
import { graphql } from "gatsby";

import SiteLayout from "../components/SiteLayout";
import Seo from "../components/Seo";
import ContactForm from "../components/ContactForm/ContactForm";
import Block from "../components/Block";
import Title from "../components/Title";
import Wysiwyg from "../components/Wysiwyg";
import Breadcrumb from "../components/BreadCrumb";
import RecentPostsWidget from "../components/RecentPostsWidget";
import WhyInvestWithUsGold from "../components/WhyInvestWithUsGold";
import WhyInvestWithUsSilver from "../components/WhyInvestWithUsSilver";
import WhyInvestWithUsPlatinum from "../components/WhyInvestWithUsPlatinum";
import PageHeader from "../components/PageHeader";
import InvestBanner from "../components/InvestBanner";
import Risks from "../components/Risks";
import { isMetalPage, getMetalPageType } from "../utils";
import { TYPE_GOLD, TYPE_SILVER, TYPE_PLATINUM } from "../constants";

const Page = props => {
    const {
        location,
        data: {
            wpgraphql: { page },
        },
    } = props;

    const {
        title,
        link,
        content,
        contactFormEmail,
        contactForm,
        slug,
        uri,
        contactFormThankYouContent,
        contactFormRedirect,
        contactFormCondition,
        contactFormConditionLink,
        contactFormConditionLinkLabel,
        formButtonLabel,
        formButtonLink,
    } = page;

    let color = slug == "gold-form" ? "gold" : "blueDark";

    let type = null;
    if (slug === "investice-do-stribra") {
        type = "silver";
    } else if (slug === "investice-do-platiny") {
        type = "platinum";
    }

    return (
        <SiteLayout
            location={location}
            formButtonLabel={formButtonLabel}
            formButtonLink={formButtonLink}
            type={getMetalPageType(slug)}
        >
            <Seo
                title={`${page.seo.title || title}`}
                description={`${page.seo.metaDesc}`}
                image={page.seo.opengraphImage}
                url={link}
            />

            {!isMetalPage(slug) && (
                <Breadcrumb
                    crumbs={[{ label: page.seo.title || title, link: slug }]}
                />
            )}

            {isMetalPage(slug) && (
                <PageHeader
                    type={getMetalPageType(slug)}
                    title={title}
                    content={content}
                    image={
                        props.data[getMetalPageType(slug)].childImageSharp.fluid
                    }
                />
            )}

            {!isMetalPage(slug) && (
                <>
                    <Block leftIndent>
                        <Title type={"h1"} color={color} mb={50} mt={30}>
                            {title}
                        </Title>
                    </Block>
                    <Block radialGradient>
                        <Wysiwyg size={"text"} type={type}>
                            {content}
                        </Wysiwyg>

                        {contactForm && (
                            <ContactForm
                                uri={uri}
                                contactFormEmail={contactFormEmail}
                                contactFormThankYouContent={
                                    contactFormThankYouContent
                                }
                                contactFormCondition={contactFormCondition}
                                contactFormConditionLink={
                                    contactFormConditionLink
                                }
                                contactFormConditionLinkLabel={
                                    contactFormConditionLinkLabel
                                }
                                redirect={contactFormRedirect}
                                horizontal={true}
                                paddingY
                            />
                        )}
                    </Block>
                </>
            )}

            {getMetalPageType(slug) === TYPE_GOLD && (
                <WhyInvestWithUsGold mb={0} />
            )}

            {getMetalPageType(slug) === TYPE_SILVER && (
                <WhyInvestWithUsSilver mb={0} />
            )}

            {getMetalPageType(slug) === TYPE_PLATINUM && (
                <WhyInvestWithUsPlatinum mb={0} />
            )}

            {isMetalPage(slug) && (
                <>
                    <InvestBanner type={getMetalPageType(slug)} />
                    <Risks
                        images={{
                            levneZlato:
                                props.data.levneZlato.childImageSharp.fluid,
                            krachDodavatele:
                                props.data.krachDodavatele.childImageSharp
                                    .fluid,
                            vysokyPoplatek:
                                props.data.vysokyPoplatek.childImageSharp.fluid,
                            zadnePadelky:
                                props.data.zadnePadelky.childImageSharp.fluid,
                        }}
                    />
                    <RecentPostsWidget titleColor="grayBlueDark" />
                </>
            )}
        </SiteLayout>
    );
};

export default Page;

export const pageQuery = graphql`
    query GET_PAGE($id: ID!) {
        wpgraphql {
            page(id: $id) {
                id
                title
                link
                subheading
                seo {
                    title
                    metaDesc
                    opengraphImage
                }
                content
                uri
                slug
                contactFormEmail
                contactForm
                contactFormRedirect
                contactFormThankYouContent
                contactFormCondition
                contactFormConditionLink
                contactFormConditionLinkLabel
                formButtonLabel
                formButtonLink
            }
        }
        gold: file(relativePath: { eq: "gold-brick.png" }) {
            childImageSharp {
                fluid(maxWidth: 771, quality: 100) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        silver: file(relativePath: { eq: "silver-brick.png" }) {
            childImageSharp {
                fluid(maxWidth: 771, quality: 100) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        platinum: file(relativePath: { eq: "platinum-brick.png" }) {
            childImageSharp {
                fluid(maxWidth: 771, quality: 100) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        levneZlato: file(relativePath: { eq: "levne-zlato.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 546, quality: 100) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        krachDodavatele: file(relativePath: { eq: "krach-dodavatele.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 546, quality: 100) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        vysokyPoplatek: file(relativePath: { eq: "vysoky-poplatek.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 546, quality: 100) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        zadnePadelky: file(relativePath: { eq: "zadne-padelky.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 546, quality: 100) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`;
