import React from "react";
import slugify from "slugify";

import style from "./form.module.sass";
import cx from "classnames";

export const CustomInputComponent = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {
    const inputClasses = cx({
        [style.group]: true,
        [style.horizontal]: Boolean(props.horizontal),
    });

    return (
        <div className={inputClasses}>
            <label className={style.label} htmlFor={slugify(props.label)}>
                <span>{props.label}</span>
                <span>{props.smallLabel}</span>
            </label>
            <div className={style.control}>
                <input
                    type="text"
                    {...field}
                    {...props}
                    id={slugify(props.label)}
                />
                {touched[field.name] && errors[field.name] && (
                    <div className={style.error}>{errors[field.name]}</div>
                )}
            </div>
        </div>
    );
};

// Input feedback
export const InputFeedback = ({ error }) =>
    error ? <div className={style.error}>{error}</div> : null;

// Checkbox input
export const Checkbox = ({
    field: { name, value, onChange, onBlur },
    form: { errors, touched, setFieldValue },
    id,
    label,
    gdprLabel,
    contactFormCondition,
    contactFormConditionLink,
    className,
    children,
    ...props
}) => (
    <div className={style.checkbox}>
        <input
            name={name}
            id={id}
            type="checkbox"
            value={value}
            checked={value}
            onChange={onChange}
            onBlur={onBlur}
            className="radio-button"
        />
        {children}
        {touched[name] && <InputFeedback error={errors[name]} />}
    </div>
);

export const RadioButtonGroup = ({
    value,
    error,
    touched,
    id,
    label,
    className,
    children,
}) => (
    <div className={style.checkGroup}>
        <fieldset>
            <legend className={style.checkGroupLabel}>{label}</legend>
            {children}
            {touched && <InputFeedback error={error} />}
        </fieldset>
    </div>
);

// Radio input
export const RadioButton = ({
    field: { name, value, onChange, onBlur },
    id,
    label,
    ...props
}) => (
    <div className={style.radio}>
        <input
            name={name}
            id={id}
            type="radio"
            value={id} // could be something else for output?
            checked={id === value}
            onChange={onChange}
            onBlur={onBlur}
            className="radio-button"
            {...props}
        />
        <label htmlFor={id}>{label}</label>
    </div>
);
