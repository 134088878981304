import React, { useState, Fragment, useContext } from "react";
import { Formik, Field } from "formik";
import { navigate } from "gatsby";
import * as yup from "yup";
import cx from "classnames";

import contactStyle from "./contact.module.scss";
import ContactFormMutation from "./ContactFormMutation";
import {
    CustomInputComponent,
    RadioButtonGroup,
    RadioButton,
    Checkbox,
} from "./CustomInput";
import Button from "../Button";
import { checkoutContext } from "../../context/checkoutContextProvider";

const contactSchema = yup.object().shape({
    name: yup.string().required("Vyplňte jméno"),
    phone: yup
        .string()
        .max(13, "Telefonní číslo je moc dlouhé")
        .min(9, "Telefonní číslo je moc krátké")
        .required("Vyplňte tel. číslo"),
    city: yup.string().required("Vyplňte město"),
    gdpr: yup.bool().oneOf([true], "Musíte souhlasit s podmínkami"),
});

const ContactForm = props => {
    const [open, setOpen] = useState(false);

    const { adviser } = useContext(checkoutContext);

    const initialState = {
        name: "",
        phone: "",
        phoneTime: "dopoledne",
        city: "",
        gdpr: false,
        adviser,
    };

    const formClasses = cx({
        [contactStyle.form]: true,
        [contactStyle.formPaddingY]: props.paddingY,
        [contactStyle.horizontal]: Boolean(props.horizontal),
    });

    const {
        contactFormCondition,
        contactFormConditionLink,
        contactFormConditionLinkLabel,
        contactFormConditionAfter,
    } = props;

    return (
        <div>
            {open ? (
                <div className={contactStyle.thankYou}>
                    {props.contactFormThankYouContent}
                    <Button
                        mt={20}
                        color={"button-outline-blue-dark"}
                        type="submit"
                        onClick={() => setOpen(false)}
                    >
                        Rozumím
                    </Button>
                </div>
            ) : (
                ""
            )}

            {!open && (
                <Formik
                    initialValues={initialState}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        setSubmitting(true);
                        values.uri = props.uri;
                        values.type = "contact";
                        await ContactFormMutation(values);
                        resetForm(initialState);
                        props.redirect ? navigate(props.redirect) : "";
                        setOpen(true);
                        setSubmitting(false);
                    }}
                    validationSchema={contactSchema}
                    render={props => (
                        <Fragment>
                            <form
                                onSubmit={props.handleSubmit}
                                className={formClasses}
                            >
                                <div className={contactStyle.inline}>
                                    <Field
                                        name="name"
                                        component={CustomInputComponent}
                                        placeholder="Jméno a příjmení"
                                        label="Jméno a příjmení"
                                        smallLabel="Abychom věděli, jak vás oslovit."
                                        horizontal="horizontal"
                                    />
                                    <Field
                                        name="phone"
                                        component={CustomInputComponent}
                                        placeholder="Telefon"
                                        label="Telefon"
                                        smallLabel="Na jakém čísle vám zastihneme?"
                                        horizontal="horizontal"
                                    />
                                </div>

                                <div className={contactStyle.inline}>
                                    <RadioButtonGroup
                                        id="radioGroup"
                                        value={props.values.radioGroup}
                                        error={props.errors.radioGroup}
                                        touched={props.touched.radioGroup}
                                    >
                                        <Field
                                            component={RadioButton}
                                            name="phoneTime"
                                            id="dopoledne"
                                            label="Volejte mi raději dopoledne do 12:00"
                                        />
                                        <Field
                                            component={RadioButton}
                                            name="phoneTime"
                                            id="odpoledne"
                                            label="Volejte mi raději odpoledne od 12:00"
                                        />
                                    </RadioButtonGroup>
                                    <Field
                                        name="city"
                                        component={CustomInputComponent}
                                        placeholder="Město"
                                        label="Město"
                                        smallLabel="Ať vám najdeme nejbližšího poradce."
                                    />
                                </div>

                                <Field
                                    component={Checkbox}
                                    name="gdpr"
                                    id="gdpr"
                                >
                                    {contactFormCondition && (
                                        <label htmlFor="gdpr">
                                            {contactFormCondition}

                                            <a
                                                target={"_blank"}
                                                href={contactFormConditionLink}
                                                rel="noopener noreferrer"
                                            >
                                                {contactFormConditionLinkLabel}
                                            </a>
                                            {contactFormConditionAfter}
                                        </label>
                                    )}
                                </Field>
                                <Button
                                    type="submit"
                                    disabled={props.isSubmitting}
                                    width={"full"}
                                    mt={20}
                                >
                                    {props.isSubmitting
                                        ? "Odesílám…"
                                        : "Zavolejte mi"}
                                </Button>
                            </form>
                        </Fragment>
                    )}
                />
            )}
        </div>
    );
};

export default ContactForm;
