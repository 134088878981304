const query = `mutation SEND_INQUIRY($input: SendInquiryInput!) {
  sendInquiry(input: $input) {
    status
    message
  }
}
`;

export default async formData => {
    const graphqlUrl =
        process.env.WP_GRAPHQL_URI ||
        process.env.GATSBY_WP_GRAPHQL_URI ||
        "/graphql";

    const result = await fetch(graphqlUrl, {
        // eslint-disable-line no-undef
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({
            query,
            variables: {
                input: {
                    clientMutationId: "kontakt-" + new Date().toString(),
                    name: formData.name ? formData.name : "",
                    phone: formData.phone ? formData.phone : "",
                    phoneTime: formData.phoneTime ? formData.phoneTime : "",
                    city: formData.city ? formData.city : "",
                    gdpr: formData.gdpr,
                    email: formData.email ? formData.email : "",
                    coinName: formData.coinName ? formData.coinName : "",
                    type: formData.type,
                    uri: formData.uri ? formData.uri : "",
                    note: formData.adviser ? formData.adviser : "",
                },
            },
        }),
    });
    return await result.json();
};
