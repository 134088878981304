import React, { Component } from "react";
import classNames from "classnames/bind";

import styles from "./wysiwyg.module.sass";
import PropTypes from "prop-types";

let cx = classNames.bind(styles);

class Wysiwyg extends Component {
    render = () => {
        const { children, size, type } = this.props;

        let wysiwyg = cx({
            wysiwyg: true,
            wysiwygText: size === "text",
            wysiwygFull: size === "full",
            wysiwygSilver: type === "silver",
            wysiwygPlatinum: type === "platinum",
        });

        return (
            <div
                className={wysiwyg}
                dangerouslySetInnerHTML={{ __html: children }}
            />
        );
    };
}

Wysiwyg.propTypes = {
    size: PropTypes.oneOf(["full", "text"]),
};

Wysiwyg.defaultProps = {
    size: "full",
};

export default Wysiwyg;
