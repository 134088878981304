import React, { Fragment } from "react";
import moment from "moment";
import { Link } from "gatsby";
import { createLocalLink } from "../../utils";
import Block from "../Block";
import Title from "../Title";

import styles from "./recent-posts.module.sass";
import Swiper from "react-id-swiper";
import { Navigation, Pagination } from "swiper/dist/js/swiper.esm";

const RecentPosts = ({ titleColor, posts }) => {
    const params = {
        modules: [Pagination, Navigation],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            dynamicBullets: true,
        },
    };

    return (
        <Fragment>
            <Block>
                <Title
                    color={!titleColor ? "gold" : titleColor}
                    type={"h2"}
                    mb={40}
                >
                    Ze světa cenných kovů
                </Title>
                <Link
                    to={"/zajimavosti-o-investicnich-kovech/"}
                    className={styles.allNews}
                >
                    <span>Všechny články</span>
                    <span className={"icon-more-2"} />
                </Link>
            </Block>

            <Block leftIndent>
                <ul className={styles.wrapper}>
                    {posts.nodes.map(post => (
                        <li key={post.id}>
                            <Link to={createLocalLink(post.link)}>
                                {post.featuredImage ? (
                                    <div
                                        className={styles.featureImage}
                                        style={{
                                            backgroundImage:
                                                "url(" +
                                                post.featuredImage.sourceUrl +
                                                ")",
                                        }}
                                    />
                                ) : (
                                    <div className={styles.featureImageEmpty} />
                                )}

                                <div className={styles.content}>
                                    <h3>{post.title}</h3>
                                    <p className={styles.date}>
                                        {moment(post.date).format("D. M. YYYY")}
                                    </p>
                                    <span className={styles.fakeLink}>
                                        <span>Celý článek</span>
                                        <span className={"icon-more-2"} />
                                    </span>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </Block>

            <div className={styles.mobileSliderWrapper}>
                <Swiper {...params}>
                    {posts.nodes.map(post => (
                        <div key={post.id}>
                            <Link to={createLocalLink(post.link)}>
                                {post.featuredImage ? (
                                    <div
                                        className={styles.featureImage}
                                        style={{
                                            backgroundImage:
                                                "url(" +
                                                post.featuredImage.sourceUrl +
                                                ")",
                                        }}
                                    />
                                ) : (
                                    <div className={styles.featureImageEmpty} />
                                )}

                                <div className={styles.content}>
                                    <h3>{post.title}</h3>
                                    <p className={styles.date}>
                                        {moment(post.date).format("D. M. YYYY")}
                                    </p>
                                    <span className={styles.fakeLink}>
                                        <span>Celý článek</span>
                                        <span className={"icon-more-2"} />
                                    </span>
                                </div>
                            </Link>
                        </div>
                    ))}
                </Swiper>
            </div>
        </Fragment>
    );
};

export default RecentPosts;
